import { Icons } from '@/components/icons'
import { Analytics } from '@/lib/analytics'
import { withOptionalUser } from '@/lib/auth.server'
import { otpSession } from '@/lib/otp.server'
import { getToast } from '@/lib/toast.server'
import { useToast } from '@/lib/use-toast'
import { useActor } from '@fuse/core/actor'
import { User } from '@fuse/core/user'
import type { SEOHandle } from '@nasa-gcn/remix-seo'
import type { LinksFunction, LoaderFunctionArgs } from '@remix-run/node'
import { Link, Outlet, json, redirect, useLoaderData } from '@remix-run/react'
import authBgUrl from './auth-bg.jpg'

export const handle: SEOHandle = {
  getSitemapEntries: () => null,
}

export const links: LinksFunction = () => [
  { rel: 'preload', href: authBgUrl, as: 'image', type: 'image/jpg' },
]

export const loader = (args: LoaderFunctionArgs) =>
  withOptionalUser(args, async ({ request }) => {
    const actor = useActor()

    const session = await otpSession.getSession(request.headers.get('Cookie'))
    const newUser = session.get('newUser')

    if (actor.type === 'user' && !newUser) {
      throw redirect('/app/home')
    }

    const { toast, headers: toastHeaders } = await getToast(request)

    return json(
      { toast, user: actor.type === 'user' ? await User.me() : null },
      { headers: toastHeaders ?? {} },
    )
  })

export default function AuthLayout() {
  const { toast, user } = useLoaderData<typeof loader>()

  useToast(toast)
  Analytics.useIdentifiedUser(user)

  return (
    <div className='h-full relative grid lg:grid-cols-2'>
      <div className='relative hidden h-full lg:block dark:border-r'>
        <Link to='/' className='absolute top-[30px] left-14'>
          <Icons.Fusespace />
        </Link>
        <div className='h-full flex flex-col justify-center items-center px-14'>
          <img
            alt='Fuse space service'
            height={700}
            width={766}
            src={authBgUrl}
          />
        </div>
      </div>
      <div className='relative flex flex-col items-center justify-center h-full'>
        <Link
          to='/'
          className='absolute top-6 left-6 p-2 z-20 flex items-center lg:hidden'
        >
          <Icons.Fusespace />
        </Link>

        <Outlet />
      </div>
    </div>
  )
}
